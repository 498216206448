@use "../../stylesheet/elements";
@use "../../stylesheet/paging";
@use "../../stylesheet/textStyle";


.selectarea {
  width: 100%;
}

.select {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.butn {
  text-align: start;
  color: rgb(133, 133, 133);
  padding: 5px 0px;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.06em;
  width: 100%;
}

.dropdown {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 10px;
    transform: rotate(-90deg);
  }
}


.popup {
  display: flex;
  width: 150px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--Disabled-Button-Background, #D2D2D2);
  background: #FFF;

  /* Float */
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);

  .popupitem {
    display: flex;
    height: 25px;
    padding: 10px 10px;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 0;
    align-self: stretch;
    color: var(--Deep-Blue, #4C6278);
    border: 1px solid var(--Disabled-Button-Background, #D2D2D2);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
  }

}

.popoverText {
  padding: 5px;
  width: 200px;
  text-align: start;
  color: rgb(133, 133, 133);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.06em;
}

.popoverText:hover {
  cursor: pointer;
  background-color: #f0eded;
}


///////// this css is for the +new commitment popup ////////////

.npopup {
  display: flex;
  // width: 360px;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid var(--Disabled-Button-Background, #D2D2D2);
  background: #FFF;

  /* Float */
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);

  .top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;

    .toptext {
      color: var(--Deep-Blue, #4C6278);
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .tline {
    height: 1px;
    align-self: stretch;
    background: var(--Disabled-Button-Background, #D2D2D2);
  }

  .middle {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;

    .middlebox {
      display: flex;
      padding: 10px 5px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;
      border-radius: 5px;

      .middleboxtop {
        align-self: stretch;
        color: var(--Secondary-Colour, #424242);
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .middleboxtoperror{
        align-self: stretch;
        color: var(--Alert-Red, #D75A5A);
        text-align: center;

        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .middleboxdata {
        display: flex;
        height: 22px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--Disabled-Button-Text, #7A7A7A);
        text-align: center;
        border: 0;

        /* T1 */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .middleboxdataerror {
        display: flex;
        height: 22px;
        flex-direction: column;
        justify-content: center;
        align-self: stretch;
        color: var(--Alert-Red, #D75A5A);
        text-align: center;
        border: 0;

        /* T1 */

        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .line {
      width: 1px;
      align-self: stretch;
      background: var(--Disabled-Button-Background, #D2D2D2);
    }
  }

  .bottom {
    display: flex;
    width: 130px;
    padding: 5px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .bottombuttons {
      flex: 1 0 0;
      color: var(--Light-Blue, #6E8CA9);
      text-align: center;
      // font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

}

.userpage {
  @include paging.mainPage();

  .pageTitle {
    .pageHeader {
      @media screen and (max-width: 640px) {
        @include textStyle.pageHeaderMobile();
      }
    }
  }

  .mobileButton {
    margin-top: 10px;
    display: none;

    @media screen and (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .buttonContent {
        @include elements.phoneButton();
      }
    }
  }

  .pageContentMobile {
    display: none !important;

    @media screen and (max-width: 640px) {
      display: flex !important;
    }
  }

  .pageContent {
    @media screen and (max-width: 640px) {
      display: none !important;
    }
  }

  .pageContentMobile,
  .pageContent {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;

    @media screen and (max-width: 640px) {
      justify-content: center;
      padding-top: 20px;
    }

    .customerActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .carInfo {
        width: 300px;
        background: linear-gradient(180deg,
            #c8c8c8 0%,
            rgba(206, 206, 205, 0.34) 100%);
        padding: 1px 8px;
        border-radius: 10px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.405);
        border: 1px solid rgba(0, 0, 0, 0.213);

        .title {
          h1 {
            font-weight: 500;
            font-size: 18px;
          }
        }

        .image {
          display: flex;
          justify-content: center;

          img {
            width: 285px;
            height: 210px;
            border-radius: 25px;
          }
        }

        .content {
          padding: 5px;

          p {
            display: grid;
            grid-template-columns: 45% auto;
            font-size: 12px;
            justify-items: start;
          }
        }

        .button {
          padding: 10px 5px;

          button {
            @include elements.buttonC();
            width: 100%;
          }
        }
      }

      //end of carInfo

      .contractOptions {
        @include elements.infoTile(300px);

        .buttons {
          display: grid;
          grid-template-columns: auto auto;
          grid-template-areas:
            "viewContract viewContract"
            // "updateContract amendLOA"
            "tokenSellout customSellout"
            "unlockSellout newLOA";
          column-gap: 10px;
          row-gap: 10px;

          button {
            @include elements.buttonC();
            height: auto !important;
          }

          .viewContract {
            grid-area: viewContract;
          }

          .updateContract {
            grid-area: updateContract;
          }

          .amendLOA {
            grid-area: amendLOA;
          }

          .tokenSellout {
            grid-area: tokenSellout;
            opacity: 0.4;

            &:active {
              opacity: 1;
            }
          }

          .customSellout {
            grid-area: customSellout;
            opacity: 0.4;

            &:active {
              opacity: 1;
            }
          }

          //   .useTokenButton {
          //     grid-area: useTokenButton;
          //   }
          .unlockSellout {
            grid-area: unlockSellout;
          }
        }
      }

      //end of contractOptions

      .emailTemplates {
        @include elements.infoTile(300px);

        .buttons {
          display: flex;
          flex-direction: column;
          gap: 10px;

          button {
            @include elements.buttonC();
          }
        }
      }

      //end of emailTemplates

      .files {
        @include elements.infoTile(300px);

        // margin: 20px 0px;
        .title {
          h1 {
            font-size: 18px;
            font-weight: 500;
          }
        }

        .buttons {
          display: grid;
          grid-template-columns: 129.5px 129.5px;
          grid-template-areas: "viewContract copyLink";
          column-gap: 10px;

          button {
            @include elements.buttonC();
            width: 100%;
            height: auto !important;
          }
        }
      }

      //end of files

      .withdrawalRequest {
        @include elements.infoTile(300px);

        .content {
          .status {
            height: 30px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1px 10px;
            font-size: 12px;
            margin-top: 10px;

            p {
              margin: 0px;
            }
          }
        }

        .buttons {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;

          button {
            width: 48%;
          }

          .approveButton {
            @include elements.buttonA();
          }

          .denyButton {
            @include elements.buttonC();
          }
        }
      }

      //end of withdrawalRequest
    }

    //end of CustomerAction

    .customerInfo {
      display: flex;
      flex-direction: column;
      gap: 30px;

      @media screen and (max-width: 640px) {
        width: 100%;
        align-items: center;
      }

      .noteInfo {
        @include elements.infoTile(500px);
        display: flex;
        padding-top: 15px;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .notes {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          border: 1px solid #8f8f8f;
          border-radius: 7px;
          margin-top: 10px;
          padding: 5px;

          input {
            @include elements.inputField();
            width: 100%;
            font-size: 12px;
            height: 60px;
            margin: 0px 10px 0px 0px;
          }

          .button {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            margin-top: 10px;

            .editButton {
              @include elements.buttonC();
            }

            .saveButton {
              @include elements.buttonA();
            }
          }

          .noteButtons {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-right: 10px;

            button {
              width: 45px !important;
              height: 20px;
              background: #eeeeee;
              border: 0.5px solid rgba(0, 0, 0, 0.5);
              border-radius: 5px;
              font-size: 12px;
              letter-spacing: -0.06em;
            }

            .editButton {
              &:hover {
                box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
              }

              &:active {
                box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
              }
            }

            .deleteButton {
              &:hover {
                box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
              }

              &:active {
                box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
              }
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          margin-top: 10px;

          .newNoteButton {
            @include elements.buttonC;
            width: 130px;
          }
        }
      }

      .contactInfo {
        @include elements.infoTile(500px);
        // margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .nameInput {
            display: flex;
            gap: 10px;
          }

          input {
            border-radius: 7px;
            border: 1px solid rgba(0, 0, 0, 0.556);
            padding: 5px;
          }

          .refCodeUser {
            @include elements.inputWithCopy();
          }
        }

        .button {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 10px;

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }
        }
      }

      //end of ContactInfo

      .transactionHistory {
        @include elements.infoTile(500px);
        // margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-height: 300px;
          overflow-y: auto;
          padding-right: 3px;

          .contentCard {
            display: grid;
            grid-template-columns: 20% 65% 15%;
            grid-template-areas: "date title price";
            justify-content: space-between;
            border: 1px solid rgba(0, 0, 0, 0.522);
            padding: 7px;
            border-radius: 10px;
            align-items: center;

            @media screen and (max-width: 640px) {
              padding: 10px;
              grid-template-columns: auto auto auto;
              // grid-template-rows: 25px 30px 15px;
              grid-template-areas:
                "date date date"
                "title title title"
                "price price price";
              align-items: flex-start;
            }

            .date {
              grid-area: date;
              font-weight: 400;
              font-size: 12px;
            }

            .name {
              grid-area: title;
              font-weight: 600;
              font-size: 12px;
            }

            .price {
              grid-area: price;
              font-weight: 600;
              font-size: 12px;
              justify-self: end;
              color: #702632;

              @media screen and (max-width: 640px) {
                justify-self: end;
              }
            }
          }
        }

        .disclaimer {
          font-size: 12px;
          line-height: 15px;
          font-weight: 400;
          margin-top: 20px;
          align-items: center;
          color: #000000;
          font-style: normal;
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 10px;

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }
        }
      }

      //end of transactionHistory

      .paymentTracker {
        @include elements.infoTile(500px);
        // margin: 20px 0px;

        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .trackerTable {
          overflow-x: auto;
          padding: 10px 5px;

          &::-webkit-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            display: none;
            /* Hide scrollbar for IE, Edge and Firefox */
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */
          }

          table {
            border-collapse: collapse;

            @media screen and (max-width: 640px) {
              width: 300px;
              overflow-x: scroll;

              &::-webkit-scrollbar {
                /* Hide scrollbar for Chrome, Safari and Opera */
                display: none;
                /* Hide scrollbar for IE, Edge and Firefox */
                -ms-overflow-style: none;
                /* IE and Edge */
                scrollbar-width: none;
                /* Firefox */
              }
            }

            .months {
              th {
                background: #cccccc;
                font-size: 14px;
                font-weight: 500;
              }

              .empty {
                border: none;
                background: white;
              }
            }

            th {
              width: 100px;
              height: 40px;
              border: 1px solid rgb(0, 0, 0);
              padding: 0px 20px;
              font-size: 12px;
              font-weight: 400;
            }

            .category {
              background: #cccccc;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }

      .customerAsset {
        @include elements.infoTile(500px);
        // margin: 20px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-bottom: 10px;

          .assetCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #eeeeee;
            padding: 7px 10px;
            border: 1px solid #000000;
            border-radius: 7.5px;

            .carName {
              font-size: 12px;
              font-weight: 400;
              color: #000000;
            }

            .status {
              font-size: 12px;
              font-weight: 600;
              color: #000000;
            }
          }

          .Active {
            background: #ffffff !important;
          }
        }
      }

      //end of customerAsset





      ////// new designs for leasepayment system
      .outerbox {
        display: flex;
        width: 500px;
        height: 500px;
        flex-direction: column;
        align-items: center;
        flex-shrink: 0;
        border-radius: 5px;
        border: 1px solid #FFF;
        background: rgba(255, 255, 255, 0.10);

        /* Botoom */
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.15), 0px -20px 20px 0px rgba(255, 255, 255, 0.75) inset;
        backdrop-filter: blur(5px);

        .buttons {
          display: flex;
          padding: 20px 50px;
          align-items: flex-start;
          gap: 10px;
          align-self: stretch;

          .titlebutton {
            color: var(--Deep-Blue, #4C6278);
            flex: 1 0 0;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-family: 'Lato', sans-serif;
          }
        }

        .entry {
          display: flex;
          width: 400px;
          padding: 15px 10px;
          justify-content: center;
          align-items: center;
          border: 1px solid #FFF;
          background: rgba(255, 255, 255, 0.50);

          .side1 {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex: 1 0 0;
            align-self: stretch;

            .side1title {
              display: flex;
              align-items: center;
              gap: 5px;
              align-self: stretch;

              .side1titletext {
                color: var(--Secondary-Colour, #424242);
                font-size: 12px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                font-family: 'Lato', sans-serif;
              }
            }

            .side1text {
              color: var(--Secondary-Colour, #424242);

              /* T1 */
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              font-family: 'Lato', sans-serif;
            }

          }

          .side2 {
            display: flex;
            height: 27px;
            padding: 5px 10px;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            flex-direction: row;

            .side2text {
              color: var(--Secondary-Colour, #424242);
              font-size: 14px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              font-family: 'Lato', sans-serif;

            }
          }
        }

      }












    }

    //end of customerInfo
  }
}

/**************** CUSTOM SELLOUT POP-OUT **********************/
.customSelloutPop {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 450px;
  padding: 20px 10px;

  @media screen and (max-width: 640px) {
    width: 300px;
  }

  .customSelloutTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.316);

    .title {
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 10px;
    }

    button {
      border-radius: 5px;
      width: 30px;
      height: 30px;
      background: #c4c4c4;
      border: 0.2px solid #8b8b8b52;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }

  input {
    @include elements.inputField();
    border: 1px solid #00000040;
    font-family: Montserrat;
    letter-spacing: -0.06em;
  }

  .cancel {
    @include elements.buttonC();
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: -0.06em;
    font-size: 15px;
  }
}


////////////////// leasepayment popup/////////////////

.paymentdialog {
  display: inline-flex;
  max-height: 400px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px;

  /* Vision Glow */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(12.5px);

  .head {
    display: flex;
    height: 50px;
    padding: 0px 25px;
    justify-content: space-around;
    align-items: center;
    align-self: stretch;
    background: var(--Deep-Blue, #4C6278);

    .headingtext {
      color: var(--Background-Off-White, #F8F6F4);
      text-align: center;

      /* H3 */
      font-size: 18px;
      font-style: normal;
      line-height: normal;
    }
  }

  .body {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    background: rgba(221, 221, 221, 0.50);

    .entry {
      display: flex;
      width: 400px;
      padding: 15px 10px;
      justify-content: center;
      align-items: center;
      border: 1px solid #FFF;
      background: rgba(255, 255, 255, 0.50);

      .side1 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 0 0;
        align-self: stretch;

        .side1title {
          display: flex;
          align-items: center;
          gap: 5px;
          align-self: stretch;

          .side1titletext {
            color: var(--Secondary-Colour, #424242);
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            font-family: 'Lato', sans-serif;
          }
        }

        .side1text {
          color: var(--Secondary-Colour, #424242);

          /* T1 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          font-family: 'Lato', sans-serif;
        }

      }

      .side2 {
        display: flex;
        height: 27px;
        padding: 5px 10px;
        align-items: center;
        gap: 10px;
        border-radius: 10px;
        flex-direction: row;

        .side2text {
          color: var(--Secondary-Colour, #424242);
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          font-family: 'Lato', sans-serif;
        }
      }
    }

    .buttons {
      display: flex;
      padding: 5px 0px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;

      .cancel {
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 50px;
        border: 1px solid var(--Disabled-Button-Text, #7A7A7A);

        .canceltext {
          flex: 1 0 0;
          color: var(--Secondary-Colour, #424242);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          font-family: 'Lato', sans-serif;
        }
      }

      .set {
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 50px;
        border: 1px solid var(--Deep-Blue, #4C6278);
        background: var(--Light-Blue, #6E8CA9);

        .settext {
          flex: 1 0 0;
          color: var(--Wealth-background, #F4F3F2);
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          font-family: 'Lato', sans-serif;
        }
      }

      .delete {
        display: flex;
        padding: 10px 0px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 50px;
        border: 1px solid var(--Alert-Red, #D75A5A);
        background: var(--Error-Red, #D38282);

        .deletetext {
          flex: 1 0 0;
          color: var(--Wealth-background, #F4F3F2);
          text-align: center;
          font-family: 'Lato', sans-serif;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}