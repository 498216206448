@use '../stylesheet/textStyle';
@use '../stylesheet/elements';
@use '../stylesheet/paging';


.platform{
    @include paging.mainPage();
    
    .pageHeader{
        font-weight: 400 !important;
        @media screen and (max-width: 640px){
            font-size: 47px !important;
        }
        
        b{
            font-weight: 600;
        }
        
    }


    .pageContent{
        @include paging.mainPageContent();

        .searchBar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #FFFFFF;
            padding: 15px 15px 5px 15px;
            border: 0.5px solid #8F8F8F; 
            box-shadow: 0px 0px 2px 0px #00000080;
            border-radius: 25px;

            .inputBorder{
                @include elements.searchInput();
            }

            .filterBar{
                @include elements.filterTile();
            }
        }//end of searchBar

        .filterBar{
            @include elements.filterTile();
            .bottom{
                .active{
                    font-weight: 600;
                }
            }
        }//end of filterBar

        .cardsSection{
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media screen and (max-width: 640px){
                align-items: center;
            }

            .userCard{
                @include elements.dataEntry();
                display: grid;
                grid-template-columns: 45% 35% 20%;
                grid-template-areas: "userName email phone";
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 640px){
                    padding: 10px;
                    grid-template-columns: auto auto auto;
                    grid-template-areas: "phone phone phone"
                                          "userName userName userName"
                                          "email email email";
                    align-items: flex-start;
                }
                
               .userName{
                    font-weight: 400;
                    font-size: 14px;
                    grid-area: userName;
               }

               .phone{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: phone;
                    justify-self: end;
                    @media screen and (max-width: 640px){
                        justify-self: start;
                    }
                }
                
                .email{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: email;
                    @media screen and (max-width: 640px){
                        justify-self: end;
                    }
                }
            }// end of userCard

            .assetCard{
                @include elements.dataEntry();
                display: grid;
                grid-template-columns: 60% 20% 20%;
                grid-template-areas: "year token months";
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 640px){
                    padding: 10px;
                    grid-template-columns: auto auto auto;
                    grid-template-areas: "months months months"
                                          "year year year"
                                          "token token token";
                    align-items: flex-start;
                }
                
               .year{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: year;
               }

               .months{
                    font-weight: 400;
                    font-size: 14px;
                    grid-area: months;
                    justify-self: end;
                    @media screen and (max-width: 640px){
                        justify-self: start;
                    }
                }
                

                .token{
                    font-weight: 500;
                    font-size: 14px;
                    grid-area: token;
                    @media screen and (max-width: 640px){
                        justify-self: end;
                    }
                }
            }//end of assetCard
            
        }
    }
}