@use "../stylesheet/textStyle";
@use "../stylesheet/elements";
@use "../stylesheet/paging";

.operationsPage {
  @include paging.mainPage();

  .pageContent {
    @include paging.mainPageContent();
    .searchArea {
      display: grid;
      grid-template-columns: 62% 35%;
      justify-content: space-between;
      gap: 10px;
      .searchBar {
        // display: flex;
        // align-items: center;
        background: #ffffff;
        // width: 490px;
        // padding: 15px;
        // border: 0.5px solid #8f8f8f;
        // box-shadow: 0px 0px 2px 0px #00000080;
        // border-radius: 25px;

        .inputBorder {
          @include elements.searchInput();
        }
      }
      .toggleButtons {
        //  width: 300px;
        height: 30px;
        display: flex;
        justify-content: space-around;
        background: #eeeeee;
        border: 1px solid black;
        border-radius: 10px;

        button {
          height: 100%;
          border: none;
          width: 100%;
          padding: 5px;
          border-radius: 10px;
          background: none;
          transition: 0.2s ease;
          font-size: 12px;
          font-weight: 400;
          -webkit-text-fill-color: #000000;
        }

        .active {
          background: #c4c4c4;
        }
      }
    }

    .operationSection {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media screen and (max-width: 640px) {
        align-items: center;
      }

      .operationCard {
        @include elements.dataEntry();
        display: grid;
        grid-template-columns: 35% 50% 15%;
        grid-template-areas: "customerName yearMakeModel phoneNumber userId newDeal";
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 640px) {
          // grid-template-columns: auto auto auto auto auto;
          grid-template-columns: auto auto auto;
          grid-template-areas:
            // "newDeal newDeal newDeal"

            "phoneNumber phoneNumber phoneNumber"
            // "userId userId userId"
            "customerName customerName customerName"
            "yearMakeModel yearMakeModel yearMakeModel";
          align-items: flex-start;
        }

        .customerName {
          font-weight: 600;
          font-size: 14px;
          grid-area: customerName;
        }

        .phoneNumber {
          font-weight: 400;
          font-size: 14px;
          grid-area: phoneNumber;
          justify-self: end;
          @media screen and (max-width: 640px) {
            justify-self: start;
          }
        }

        .yearMakeModel {
          font-weight: 600;
          font-size: 14px;
          grid-area: yearMakeModel;
          @media screen and (max-width: 640px) {
            justify-self: end;
          }
        }
        // .userId {
        //   font-weight: 400;
        //   font-size: 14px;
        //   grid-area: userId;
        // }

        // .newDeal {
        //   font-weight: 100;
        //   font-size: 14px;
        //   grid-area: newDeal;
        //   // margin-top: -20px;
        //   margin-left: 40px;
        //   @media screen and (max-width: 640px) {
        //     justify-self: start;
        //   }
        // }
      }
      .userCard {
        @include elements.dataEntry();
        display: grid;
        grid-template-columns: 45% 35% 20%;
        grid-template-areas: "userName email phone";
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 640px) {
          padding: 10px;
          grid-template-columns: auto auto auto;
          grid-template-areas:
            "phone phone phone"
            "userName userName userName"
            "email email email";
          align-items: flex-start;
        }

        .userName {
          font-weight: 400;
          font-size: 14px;
          grid-area: userName;
        }

        .phone {
          font-weight: 600;
          font-size: 14px;
          grid-area: phone;
          justify-self: end;
          @media screen and (max-width: 640px) {
            justify-self: start;
          }
        }

        .email {
          font-weight: 600;
          font-size: 14px;
          grid-area: email;
          @media screen and (max-width: 640px) {
            justify-self: end;
          }
        }
      }
    }
  }
}
