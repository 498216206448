@use '../../stylesheet/elements';
@use '../../stylesheet/textStyle';
@use '../../stylesheet/paging';

.deals{
    @include paging.mainPage();

    .pageContent{
        @include paging.mainPageContent();

        .searchBar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #FFFFFF;
            padding: 15px 15px 5px 15px;
            border: 0.5px solid #8F8F8F; 
            box-shadow: 0px 0px 2px 0px #00000080;
            border-radius: 25px;

            .inputBorder{
                @include elements.searchInput();
            }

            .filterBar{
                @include elements.filterTile();

                .activeFilter{
                    font-weight: 700;
                }
            }
        }//end of searchBar

        .dealsSection{
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media screen and (max-width: 640px){
                align-items: center;
            }

            .dealCard{
                @include elements.dataEntry();
                display: grid;
                grid-template-columns: 50% 30% 20%;
                grid-template-areas: "year email status";
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 640px){
                    padding: 10px;
                    grid-template-columns: auto auto auto;
                    grid-template-areas: "status status status"
                                          "year year year"
                                          "email email email";
                    align-items: flex-start;
                }
                
               .year{
                    font-weight: 400;
                    font-size: 14px;
                    grid-area: year;
               }

               .status{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: status;
                    justify-self: end;
                    @media screen and (max-width: 640px){
                        justify-self: start;
                    }
                }
                

                .email{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: email;
                    @media screen and (max-width: 640px){
                        justify-self: end;
                    }
                }
            }//end of notificationSection
            
        }
    }
}