@use "../../components/stylesheet/textStyle";
@use "../../components/stylesheet/elements";

.activeCoreTab {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 239px;
  background: #dddddd;
  height: 100vh;
  overflow-y: hidden;

  a {
    text-decoration: none;
    color: white;
  }

  //only being displayed with window-size
  .topTabWeb {
    display: flex;
    // background: url('../../assets/backgroundTab.png');
    background: url("../../assets/newBackgroundTab.png");
    color: white;
    max-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    @media screen and (max-width: 640px) {
      height: 0%;
      display: none;
    }

    h2 {
      margin: 0px;
      padding: 20px;
      font-style: italic;
      font-weight: bold;
      font-size: 35px;
    }
  }
}

//only being displayed with mobile-size
.topTabMobile {
  background: url("../../assets/backgroundTab.png");
  color: white;
  height: 95px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  display: none;
  padding: 10px 20px;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 640px) {
    display: flex;
  }

  .css-6iz4mj-MuiButtonBase-root-MuiIconButton-root {
    display: flex;
    align-items: flex-end;
  }
  .arrowButton {
    background: white;
    width: 65px;
    height: 32px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      opacity: 0.4;
    }
  }

  .activeCoreTitle {
    display: flex;
    align-items: flex-end;
    height: 100%;
    a {
      text-decoration: none;
      color: white;
    }
    h2 {
      margin: 0px;
      font-style: italic;
      font-weight: bold;
      font-size: 35px;
    }
  }
}

.contentTab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 89vh;
  overflow-x: hidden;

  @media screen and (max-width: 640px) {
    height: 100vh;
  }

  .midTab {
    .userTab {
      @include elements.userTile();
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 10px;
      margin: 20px 4px;

      .userImage {
        padding-left: 10px;
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
        }
      }

      .userDescription {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0px;

        .userName {
          font-family: Montserrat;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          text-align: left;
          letter-spacing: 0em;
        }

        .email {
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          text-align: left;
          color: #00000099;
          letter-spacing: 0em;
        }
      }
    } //end of userTab

    .tabOptions {
      margin-top: 40px;

      .tabDiv {
        height: 60px;
        &:hover {
          background: linear-gradient(
            270deg,
            #ffffff29 0%,
            #4f4e4e27 73.38%,
            #ffffff27 104.25%
          );
        }
        button {
          padding: 0px 5px;
          background: none;
          border: none;
          width: 100%;
          height: 100%;
          text-align: start;
          .tabTitle {
            @include textStyle.activeCoreText();
          }
        }
      }

      .active {
        @include elements.activeCoreTab();
        &:hover {
          background: linear-gradient(
            270deg,
            #ffffff75 0%,
            #4f4e4e75 73.38%,
            #ffffff85 104.25%
          );
        }
      }
    } //end of tabOptions
  }

  .bottomTab {
    text-align: center;
    .dateInfo {
      padding: 10px 0px;
      p {
        margin: 5px;
        font-weight: 400;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
      }
    }
    .logoutButton {
      margin-bottom: 5px;

      button {
        background: none;
        border: none;
        font-weight: 300;
        font-size: 14px;
        -webkit-text-fill-color: #000000;
      }
    }
  }
}

//MUI Style Component
.css-d9ml5t-MuiDrawer-docked .MuiDrawer-paper {
  box-sizing: border-box;
  width: 240px;
  overflow-x: hidden;
}

.css-zxtyt4-MuiPaper-root-MuiAppBar-root {
  box-shadow: none !important;
}
