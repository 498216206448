@use "../../stylesheet/elements";
@use "../../stylesheet/paging";
@use "../../stylesheet/textStyle";

.userNamePage {
  @include paging.mainPage();
  .mobileButton {
    margin-top: 10px;
    display: none;

    @media screen and (max-width: 640px) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .buttonContent {
        @include elements.phoneButton();
      }
    }
  } // end of mobileButton

  .pageContentMobile {
    display: none !important;
    @media screen and (max-width: 640px) {
      display: flex !important;
    }
  }

  .pageContent {
    @media screen and (max-width: 640px) {
      display: none !important;
    }
  }

  .pageContentMobile,
  .pageContent {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    margin-bottom: 40px;
    @media screen and (max-width: 640px) {
      justify-content: center;
      padding-top: 1rem;
    }

    /***************** userAction *********************/
    .userAction {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .econInfo {
        @include elements.infoTile(300px);
        .econTitle {
          h1 {
            @include textStyle.econText();
            font-size: 18px;
          }
        }

        .content {
          p {
            display: grid;
            grid-template-columns: 45% auto;
            font-size: 12px;
            justify-items: start;
            margin: 10px 0px;
          }
        }

        .buttons {
          padding: 10px 0px;
          display: grid;
          grid-template-columns: auto auto;
          gap: 10px;

          button {
            @include elements.buttonC();

            b {
              margin-left: 5px;
            }
          }
        }
      } //end of econInfo

      .walletInfo {
        @include elements.infoTile(300px);
        height: auto;
        transition: all 2s ease-in-out;

        .walletContent {
          @include elements.rockerCombo();
        }

        .walletDisplay {
          .content {
            p {
              display: grid;
              grid-template-columns: 45% auto;
              font-size: 12px;
              justify-items: start;
              margin: 10px 0px;
            }
          }

          .button {
            padding-top: 10px;
            button {
              @include elements.buttonC();
              width: 100%;
            }
          }
        }
      } //end of walletInfo

      .encoreOptions {
        @include elements.infoTile(300px);
        .title {
          h1 {
            letter-spacing: normal;
          }
        }

        .encoreButtons {
          margin: 10px 0px;
          height: 30px;
          display: flex;
          justify-content: space-around;
          background: white;
          border: 1px solid black;
          border-radius: 10px;

          button {
            height: 100%;
            border: none;
            width: 50%;
            padding: 5px;
            border-radius: 8px;
            background: none;
            transition: 0.2s ease;
            font-size: 14px;
            font-weight: 400;
            -webkit-text-fill-color: #000000;
          }

          .active {
            background: #c4c4c4;
          }
        }

        .button {
          padding-top: 10px;
          button {
            @include elements.buttonC();
            width: 100%;
          }
        }
      } //end of encoreOptions

      .supportRequest {
        @include elements.infoTile(300px);

        .supportContent {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding-right: 3px;
          max-height: 300px;
          overflow-y: auto;

          .status {
            height: 30px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 7px;
            display: grid;
            grid-template-columns: 20% 60% 20%;
            align-items: center;
            padding: 1px 5px 1px 5px;
            font-size: 12px;

            .check {
              justify-self: flex-end;
            }
          }

          .noStatus {
            height: 30px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 7px;
            display: flex;
            align-items: center;
            padding: 1px 10px;
            font-size: 12px;
          }
        }

        .button {
          padding-top: 20px;
          button {
            @include elements.buttonC();
            width: 100%;
          }
        }
      } //end of supportRequest

      .withdrawalRequest {
        @include elements.infoTile(300px);

        .content {
          .status {
            height: 30px;
            border: 1px solid #000000;
            box-sizing: border-box;
            border-radius: 7px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 1px 10px;
            font-size: 12px;

            p {
              margin: 0px;
            }
          }
          .inputs {
            display: flex;
            flex-direction: column;
            gap: 10px;
  
            input {
              @include elements.inputField();
              border: 1px solid #00000040;
            }
  
            .name {
              display: grid;
              grid-template-columns: auto auto;
              gap: 10px;
              @media screen and (max-width: 640px) {
                grid-template-columns: auto;
              }
            }
  
            .refCode {
              @include elements.inputWithCopy();
            }
          }
        }

        .buttons {
          padding-top: 20px;
          display: flex;
          justify-content: space-between;

          button {
            width: 48%;
          }

          .approveButton {
            @include elements.buttonA();
          }

          .denyButton {
            @include elements.buttonC();
          }
        }
      } //end of withdrawalRequest

      .filesCard {
        @include elements.infoTile(300px);

        .button {
          padding-top: 5px;
          display: grid;
          grid-template-columns: 129.5px 129.5px;
          grid-template-areas: "viewContract copyLink";
          column-gap: 10px;
          button {
            @include elements.buttonC();
            width: 100%;
            height: auto !important;
          }
        }
      } //end of filesCard
    } //end of userAction

    /***************** userInfo *********************/
    .userInfo {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
        .noteInfo {
          @include elements.infoTile(500px);
          display: flex;
          padding-top: 15px;
          flex-direction: column;
          justify-content: space-between;
          @media screen and (max-width: 640px) {
            width: 300px;
          }
          .notes {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border: 1px solid #8f8f8f;
            border-radius: 7px;
            margin-top: 20px;
            padding: 5px;
            input {
              @include elements.inputField();
              width: 100%;
              font-size: 12px;
              height: 60px;
              margin: 0px 10px 0px 0px;
            }
  
            .button {
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: flex-end;
              margin-top: 10px;
    
              .editButton {
                @include elements.buttonC();
              }
    
              .saveButton {
                @include elements.buttonA();
              }
            }
  
            .noteButtons {
              display: flex;
              flex-direction: column;
              gap: 6px;
              margin-right: 10px;
              button {
                width: 45px !important;
                height: 20px;
                background: #eeeeee;
                border: 0.5px solid rgba(0, 0, 0, 0.5);
                border-radius: 5px;
                font-size: 12px;
                letter-spacing: -0.06em;
              }
              .editButton {
                &:hover {
                  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
                }
                &:active {
                  box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
                }
              }
              .deleteButton {
                &:hover {
                  box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
                }
                &:active {
                  box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
                }
              }
            }
          }
          .buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 10px;
            .newNoteButton {
              @include elements.buttonC;
              width: 130px;
            }
          }
        }
      .userInfoCard {
        @include elements.infoTile(500px);
        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;

          input {
            @include elements.inputField();
            border: 1px solid #00000040;
          }

          .name {
            display: grid;
            grid-template-columns: auto auto;
            gap: 10px;
            @media screen and (max-width: 640px) {
              grid-template-columns: auto;
            }
          }

          .refCode {
            @include elements.inputWithCopy();
          }
        }

        .button {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px;
          padding-top: 20px;
          font-size: 12px;

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }
        }
      } //end of userInfoCard

      .paymentInfo {
        @include elements.infoTile(500px);
        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
          div {
            .inputTitle {
              font-weight: 400;
              font-size: 12px;
              margin-bottom: 5px;
            }

            .input {
              @include elements.inputWithCopy();
              input {
                @include elements.inputField();
                border: 1px solid #00000040;
              }
            }
          }
        }

        .button {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          gap: 10px;
          padding-top: 20px;
          font-size: 12px;

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }
        }
      } // end of paymentInfo

      .history {
        @include elements.infoTile(500px);

        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-height: 300px;
          padding-right: 3px;
          overflow-x: hidden !important;

          .contentCard {
            display: grid;
            grid-template-columns: 15% 70% 15%;
            grid-template-areas: "date title price";
            justify-content: space-between;
            border: 1px solid rgba(0, 0, 0, 0.522);
            padding: 7px;
            border-radius: 10px;
            align-items: center;
            @media screen and (max-width: 640px) {
              padding: 10px;
              grid-template-columns: auto auto auto;
              grid-template-areas:
                "date date date"
                "title title title"
                "price price price";
              align-items: flex-start;
            }

            .date {
              grid-area: date;
              font-weight: 400;
              font-size: 12px;
            }

            .name {
              grid-area: title;
              font-weight: 600;
              font-size: 12px;
            }

            .price {
              grid-area: price;
              font-weight: 600;
              font-size: 12px;
              justify-self: end;
              @media screen and (max-width: 640px) {
                justify-self: end;
              }
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 20px;

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }
        }
      } //end of history

      .assetPortfolio {
        @include elements.infoTile(500px);
        @media screen and (max-width: 640px) {
          width: 300px;
        }

        .content {
          display: flex;
          flex-direction: column;
          gap: 10px;
          max-height: 300px;
          padding-right: 3px;
          overflow-x: hidden !important;

          .assetCard {
            @include elements.inputWithCopy();
            @media screen and (max-width: 640px) {
              grid-template-columns: auto;
              button {
                display: none;
                width: 0px;
              }
            }

            .contentCard {
              display: grid;
              grid-template-columns: 20% 65% 15%;
              grid-template-areas: "date car qty";
              justify-content: space-between;
              border: 1px solid rgba(0, 0, 0, 0.522);
              padding: 7px;
              border-radius: 5px;
              align-items: center;
              background: #eeeeee;
              @media screen and (max-width: 640px) {
                padding: 10px;
                grid-template-columns: auto auto auto;
                grid-template-areas:
                  "date date btn"
                  "car car car"
                  "qty qty qty";
                align-items: flex-start;
                border-radius: 10px;
              }

              .date {
                grid-area: date;
                font-weight: 400;
                font-size: 12px;
              }

              .car {
                grid-area: car;
                font-weight: 600;
                font-size: 12px;
              }

              .qty {
                grid-area: qty;
                font-weight: 600;
                font-size: 12px;
                justify-self: flex-end;
                @media screen and (max-width: 640px) {
                  justify-self: flex-end;
                }
              }

              .btn {
                display: none;
                @media screen and (max-width: 640px) {
                  display: block;
                  grid-area: btn;
                  justify-self: flex-end;
                  width: 50px;
                  font-size: 13px;
                }
              }
            }
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 20px;

          img {
            width: 50px;
            height: 50px;
          }

          button {
            background: none;
            border: none;
          }
        }
      }
    } //end of userInfo
  } //end of pageContent
}

/*************************** WALLET CONFIRMATION POP-OUT **********************/

.walletContentPop {
  height: 200px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: hidden;

  .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    margin: 0px !important;
    border-radius: 10px;
  }

  .walletTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.316);

    .title {
      font-family: Montserrat;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }

    button {
      border-radius: 5px;
      width: 30px;
      height: 30px;
      background: #c4c4c4;
      border: 0.2px solid #8b8b8b52;
      font-size: 24px;
      font-weight: 700;
    }
  }

  .enterAmount {
    display: flex;
    justify-content: center;
    gap: 10px;
    .amountInfo {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        width: 120px;
        height: 60px;
        border: 1px solid #8f8f8f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f8f8f;
        font-size: 12px;
        text-align: center;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60px;

      button {
        border-radius: 50%;
        height: 26px;
        width: 26px;
        border: 1px solid #8f8f8f;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f8f8f;
        background: none;
      }
    }
  }

  form {
    width: 100%;
    margin-top: 15px;

    input {
      font-family: Montserrat;
      letter-spacing: -0.06em;
      text-align: start;
    }

    .confirmButton {
      padding: 30px 10px 20px 10px;
      width: 100%;
      display: grid;
      grid-template-columns: auto auto;
      gap: 20px;

      .cancel {
        @include elements.buttonC();
        font-family: Montserrat;
        letter-spacing: -0.06em;
      }

      .confirm {
        @include elements.buttonA();
        font-family: Montserrat;
        letter-spacing: -0.06em;
      }
    }
  }
}

/*************************** SELL TOKENS POP-OUT **********************/
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  border-radius: 10px !important;
  margin: 20px !important;
  background: #f4f3f2;
}

.sellTokenContentPop {
  padding: 10px;
  width: 600px;
  background: #f4f3f2;
  @media screen and (max-width: 640px) {
    width: 100%;
  }

  .sellTokenTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 10px 0px 10px;

    .title {
      font-family: Montserrat;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
    }

    .close {
      @include elements.closeButton();
    }
  }

  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
    .communitySell {
      width: 270px;
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px 0px #00000040;
      padding: 10px;
      font-family: Montserrat;
      border-radius: 10px;
      background: white;

      button {
        font-family: Montserrat;
      }

      p {
        margin: 10px;
      }

      .title {
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: center;
      }

      .buttons {
        width: 100%;
        display: grid;
        gap: 10px;
        margin-top: 10px;
        grid-template-columns: auto 50%;

        .quantity {
          @include elements.buttonC();
          width: 100%;
          &::placeholder {
            font-family: Montserrat;
          }
        }

        .instantSale {
          @include elements.buttonA();
          width: 100%;
          font-size: 12px;
        }
      }
    }
  }
}

/********** CUSTOM ECON DEBIT POP-OUT ************/
.sellTokenContentCard {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 450px;
  padding: 15px 10px 20px 10px;
  @media screen and (max-width: 640px) {
    width: 300px;
  }

  .sellTokenTitle {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.316);
    .title {
      font-family: Montserrat;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 29px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 10px;
    }

    .close {
      @include elements.closeButton();
      margin-bottom: 10px;
    }
  }

  .sellTokenInput {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    align-items: center;
    input {
      @include elements.inputField();
      border: 1px solid #00000040;
      font-family: Montserrat;
      letter-spacing: -0.06em;
      width: 110px;
    }

    button {
      @include elements.buttonA();
      padding: 0px 20px;
      font-family: Montserrat;
    }
  }
}
