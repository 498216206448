@use '../stylesheet/elements';
@use '../stylesheet/textStyle';
@use '../stylesheet/paging';

.dealersPage{
    @include paging.mainPage();

    .pageContent{
        @include paging.mainPageContent();

        .searchBar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #FFFFFF;
            padding: 15px 15px 5px 15px;
            border: 0.5px solid #8F8F8F; 
            box-shadow: 0px 0px 2px 0px #00000080;
            border-radius: 25px;

            .inputBorder{
                @include elements.searchInput();
            }

            .filterBar{
                @include elements.filterTile();
            }
        }

        .dealersSection{
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media screen and (max-width: 640px){
                align-items: center;
            }

            .addNewDealership{
                @include elements.dataEntry();
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                @media screen and (max-width: 640px){
                    height: 60px;
                }
            }

            .dealerCard{
                @include elements.dataEntry();
                display: grid;
                grid-template-columns: 70% 15% 15%;
                grid-template-areas: "name phoneNumber1 phoneNumber2";
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 640px){
                    grid-template-columns: auto auto auto;
                    // grid-template-rows: 25px 30px 15px;
                    grid-template-areas: "phoneNumber2 phoneNumber2 phoneNumber2"
                                          "name   name  name"
                                          "phoneNumber1 phoneNumber1 phoneNumber1";
                    align-items: flex-start;

                }

                .name{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: name;
                }

                .phoneNumber1{
                    font-size: 14px;
                    font-weight: 600;
                    grid-area: phoneNumber1;
                    @media screen and (max-width: 640px){
                        justify-self: end;
                    }
                }

                .phoneNumber2{
                    font-size: 14px;
                    font-weight: 400;
                    grid-area: phoneNumber2;
                    justify-self: end;
                    @media screen and (max-width: 640px){
                        justify-self: start;
                    }
                }
            }
        }
    }
}