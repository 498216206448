.mainDashboard{
    display: flex;
    flex-wrap: nowrap;
    font-family: Montserrat;
    background: #F4F3F2; 
    letter-spacing: -0.06em;
    height: max-content;
    min-height: 100vh;
    padding-bottom: 2rem;
    
    button{
        font-family: Montserrat;
    }

    input{
        font-family: Montserrat;
    }
}

.content{
    @media screen and (max-width: 640px){
        width: 100%;
    }
}