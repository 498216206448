@use '../stylesheet/elements';
@use '../stylesheet/textStyle';
@use '../stylesheet/paging';

.notificationsPage{
    @include paging.mainPage();

    .pageContent{
        @include paging.mainPageContent();

        .searchBar{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background: #FFFFFF;
            padding: 15px 15px 5px 15px;
            border: 0.5px solid #8F8F8F; 
            box-shadow: 0px 0px 2px 0px #00000080;
            border-radius: 25px;

            .inputBorder{
                @include elements.searchInput();
            }

            .filterBar{
                @include elements.filterTile();
            }
        }//end of searchBar

        

        .notificationSection{
            display: flex;
            flex-direction: column;
            gap: 10px;

            @media screen and (max-width: 640px){
                align-items: center;
            }

            .notificationCard{
                @include elements.dataEntry();
                height: auto;
                display: grid;
                grid-template-columns: 40% 33% 20% 7%;
                grid-template-areas: "email state date check";
                justify-content: space-between;
                align-items: center;
                @media screen and (max-width: 640px){
                    padding: 10px;
                    grid-template-columns: auto auto auto;
                    grid-template-areas:  "date date date check"
                                          "email email email email"
                                          "state state state state";
                    align-items: flex-start;
                }
                
               .email{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: email;
               }

               .date{
                    font-weight: 400;
                    font-size: 14px;
                    grid-area: date;
                    justify-self: flex-end;
                    @media screen and (max-width: 640px){
                        justify-self: flex-start;
                    }
                }
                

                .state{
                    font-weight: 600;
                    font-size: 14px;
                    grid-area: state;
                    @media screen and (max-width: 640px){
                        justify-self: flex-end;
                        margin-top: 5px;
                    }
                }

                .check{
                    grid-area: check;
                    justify-self: flex-end;
                }
            }//end of notificationSection
            
        }
    }
}