@use './elements';
@use './textStyle';


/* Template for MainPage padding, link decoration, and pagetitle */
@mixin mainPage {
    padding: 0px 50px;

    a{
        text-decoration: none;
        color: black;
    }

    @media screen and (max-width: 640px){
        width: 100%;
        padding: 6rem 10px 10px 10px;
    }

    .pageTitle{
        p{
            font-weight: 400;
            font-size: 12px;
            margin-top: 5px;
            margin-bottom: 0px;
            @media screen and (max-width: 640px){
                margin-top: 10px;
            }
        }

        .pageHeader{
            @include textStyle.pageHeader();
            @media screen and (max-width: 640px){
                @include textStyle.pageHeaderMobile();
            }
        }

    }
}

/* PageContent of MainPage */
@mixin mainPageContent{
    padding-top: 2.5rem;
    width: 880px;

    //Tablet size
    @media screen and (max-width: 1200px) and (min-width: 640px){
        width: calc(90vw - 240px);
    }

    //Phone size
    @media screen and (max-width: 640px){
        width: 100%;
        padding-top: 0.5rem;
    }
}

