@use "../stylesheet/elements";
@use "../stylesheet/textStyle";
@use "../stylesheet/paging";

.interfacesPage {
  //   height: 100vh;
  //   width: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  @include paging.mainPage();
  .pageTitle {
    .pageHeader {
      @media screen and (max-width: 640px) {
        @include textStyle.pageHeaderMobile();
      }
    }
  }
  .pageContent {
    @media screen and (max-width: 640px) {
      display: none !important;
    }
  }
  .status {
    @include textStyle.statusText;
    color: #04681a;
  }
  .cardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .cardsSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    // grid-template-areas: auto auto;
    // justify-content: center;
    // align-items: center;
    gap: 30px;
    margin-top: 50px;
    .contentLeft {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .contentRight {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .investCard {
      @include elements.controlTile();
    }
    .onboardingCard {
      @include elements.controlTile();

      .partialStatus {
        @include textStyle.statusText;
        color: #d4af37;
      }
    }
    .econCard {
      @include elements.controlTile();
    }
    .lendingCard {
      @include elements.controlTile();
      button {
        @include elements.buttonC;
      }
    }
    .depositCard {
      @include elements.controlTile();
    }
    .withdrawCard {
      @include elements.controlTile();
    }
  }
}
