@mixin pageHeader {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.06em;
  line-height: 58.51px;
  -webkit-text-fill-color: #000000;
  color: #000000;
}

@mixin pageHeaderMobile {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.06em;
  text-align: left;
  margin-bottom: 20px;
}

@mixin activeCoreText {
  font-family: Montserrat;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  // letter-spacing: -0.06em;
  letter-spacing: -0.01em;
  text-align: left;
  color: #000000;
  -webkit-text-fill-color: #000000;
  margin: 10px;
}

@mixin econText {
  // font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  color: #000000;
  -webkit-text-fill-color: #000000;
}

@mixin statusText {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  font-style: normal;
}
