@use '../../stylesheet/elements';
@use '../../stylesheet/paging';
@use '../../stylesheet/textStyle';

.editAssetPage{
    @include paging.mainPage();

    .pageTitle{
        .pageHeader{
            @media screen and (max-width: 640px){
                @include textStyle.pageHeaderMobile();
            }
        }

    }

    .pageContent{
        padding-top: 2.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;

        @media screen and (max-width: 640px){
            padding-top: 20px;
            justify-content: center;
        }
        

        .carInfo{
            width: 300px;
            background: linear-gradient(180deg, #C8C8C8 0%, rgba(206, 206, 205, 0.34) 100%);
            padding: 1px 8px;
            border-radius: 10px;
            max-height: 700px;
            box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.405);                
            border: 1px solid rgba(0, 0, 0, 0.213);

            .title{
                h1{
                    font-weight: 500;
                    font-size: 18px;
                }
            }
            .image{
                display: flex;
                justify-content: center;
                img{
                    width: 280px;
                    height: 210px;
                    border-radius: 25px;
                }
                
            }

            .content{
                padding: 5px;
                p{
                    display: grid;
                    grid-template-columns: 45% auto;
                    font-size: 12px;
                    justify-items: start;
                    margin: 7px 0px;
                }
            }

            .button{
                padding: 10px 5px;
                button{
                    @include elements.buttonC();
                    width: 100%;
                    
                }
            }
        }//end of carInfo

        .assetInfo{
            display: flex;
            flex-direction: column;
            gap: 30px;

            .assetHistory,
            .assetTokens{
                @include elements.infoTile(500px);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: auto;
                @media screen and (max-width: 640px){
                    width: 300px;
                }
            } //end of assetHistory

            .assetTokens{
                .tokenList{
                    max-height: 250px;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding-right: 3px;

                    .tokenCard{
                        display: grid;
                        gap: 2px;
                        grid-template-columns: 55% 22% 21%;
                        grid-template-areas: "email count sale";
                        background: #f7f5f5;
                        padding: 7px 10px;
                        border: 1px solid #000000;
                        border-radius: 7.5px;
                        align-items: center;
                        @media screen and (max-width: 640px){
                            padding: 10px;
                            grid-template-columns: auto auto;
                            grid-template-areas: "email email"
                                                  "count sale";
                            align-items: flex-start;
                        }

                        .email{
                            font-size: 12px;
                            font-weight: 400;
                            color: #000000;
                            grid-area: email;
                        }

                        .count{
                            font-size: 12px;
                            font-weight: 400;
                            color: #000000;
                            grid-area: count;
                            @media screen and (max-width: 640px){
                                margin-top: 5px;
                            }
                        }

                        .sale{
                            font-size: 12px;
                            font-weight: 400;
                            color: #000000;
                            grid-area: sale;
                            justify-self: end;
                            @media screen and (max-width: 640px){
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }

            .assetHistory{
                .historyList{
                    max-height: 250px;
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    padding-right: 3px;

                    .historyCard{
                        background: #f7f5f5;
                        padding: 7px 10px;
                        border: 1px solid #000000;
                        border-radius: 7.5px;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        .date{
                            font-size: 12px;
                            font-weight: 600;
                            color: #000000;
                        }
                        .subject{
                            font-size: 12px;
                        }
                    }
                }
            }
        }
        
    }
}