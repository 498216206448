@use "../../stylesheet/elements";
@use "../../stylesheet/paging";
@use "../../stylesheet/textStyle";

.dealership {
  @include paging.mainPage();

  .pageTitle {
    .pageHeader {
      @media screen and (max-width: 640px) {
        @include textStyle.pageHeaderMobile();
      }
    }
  }

  .pageContent {
    padding-top: 2.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    gap: 30px;
    @media screen and (max-width: 640px) {
      justify-content: center;
      padding-top: 20px;
    }

    .leftSection {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .deals {
        @include elements.infoTile(300px);

        .dealContent {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 5px 0px;
          font-size: 12px;
          max-height: 300px;
          overflow-y: auto;
          padding-right: 3px;

          &:-webkit-scrollbar {
            width: 10px;
          }

          .dealCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #eeeeee;
            padding: 5px 10px;
            border-radius: 7.5px;
            border: 1px solid rgba(0, 0, 0, 0.713);

            .yearMakeModel {
              font-weight: 400;
            }

            .status {
              font-weight: 600;
            }
          }
        }

        .button {
          display: flex;
          justify-content: flex-end;
          margin-top: 10px;

          button {
            background: none;
            border: none;
            img {
              width: 55px;
              height: 50px;
              margin-top: 10px;
            }
          }
        }
      } //end of deal

      .employees {
        @include elements.infoTile(300px);

        .employeeContent {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 5px 0px;
          font-size: 12px;

          .dealCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #eeeeee;
            padding: 5px 10px;
            border-radius: 7.5px;
            border: 1px solid rgba(0, 0, 0, 0.713);

            .employeeName {
              font-weight: 400;
            }

            .salesPerson {
              font-weight: 600;
            }
          }
        }

        .addManagerButton {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;

          button {
            background: none;
            border: none;
            img {
              width: 3rem;
            }
          }
        }
      } //end of employees

      .addManagerCard {
        @include elements.infoTile(300px);

        .title {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;

          button {
            @include elements.closeButton();
          }
        }

        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
          font-size: 14px;
          margin-top: 10px;
          div {
            display: flex;
            flex-direction: column;
            gap: 5px;
            input {
              border-radius: 5px;
              border: 1px solid rgba(0, 0, 0, 0.522);
              padding: 8px;
            }

            .errorInput {
              border: 2px solid red;
            }
          }
        }

        .button {
          display: flex;
          justify-content: flex-end;
          align-items: flex-end;
          margin-top: 20px;

          button {
            @include elements.buttonC();
            width: 100px;
          }
        }
      }
    } // end of DealershipLeftSection

    .rightSection {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      @media screen and (max-width: 640px) {
        width: auto;
      }

      .reviews {
        @include elements.infoTile(500px);

        .coloredRectangle {
          height: 31px;
          background: #1f8a2a;
          border: 1px solid #000000;
          border-radius: 7.5px;
        }
      } //end of reviews

      .dealershipInfo {
        @include elements.infoTile(500px);

        .inputs {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 5px 0px;
          font-size: 12px;

          input {
            background: #ffffff;
            padding: 7px 10px;
            border-radius: 7.5px;
            border: 1px solid rgba(0, 0, 0, 0.713);
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }
        }

        .button {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-top: 15px;

          .editButton {
            @include elements.buttonC();
            width: 100px;
          }

          .saveButton {
            @include elements.buttonA();
            width: 100px;
          }
        }
      } //end of dealershipInfo

      .shipping {
        //display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        background: #ffffff;
        border: 0.5px solid rgba(143, 143, 143, 0.431372549);
        box-shadow: 0px 0px 5px rgb(0 0 0 / 40%);
        border-radius: 25px;
        padding: 5px 15px 20px 15px;
        width: 500px;

        
        @media screen and (max-width: 640px) {
          justify-content: center;
          gap: 30px;
          width: 100%;
        }

        .title {
          h1 {
            font-weight: 500;
            font-size: 18px;
          }
        }

        .shippingContent {
          gap: 10px;
          display: flex;
          flex-direction: row;

        
        
        .button {
          width: 100%;
          button {
            width: 100%;
            font-size: 12px;
            @include elements.buttonC();
          }
        }
      }

      }

      .others {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 500px;

        @media screen and (max-width: 640px) {
          justify-content: center;
          gap: 30px;
          width: 100%;
        }

        .shippingoptions

        .files,
        .inventory {
          border-radius: 25px;
          border: 1px solid rgba(0, 0, 0, 0.337);
          padding: 5px 15px 20px 15px;
          background: #ffffff;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.405);
          margin-bottom: 20px;
          width: 240px;
          @media screen and (max-width: 640px) {
            width: 300px;
            margin-bottom: 0px;
          }

          .title {
            h1 {
              font-weight: 500;
              font-size: 18px;
            }
          }

          .button {
            width: 100%;
            button {
              width: 100%;
              font-size: 12px;
              @include elements.buttonC();
            }
          }
        }
      }
    }
  }
}
