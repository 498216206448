/******** Start: ACTIVECORE TAB SECTION ********/
@mixin activeCoreTab {
  background: linear-gradient(
    270deg,
    #ffffff75 0%,
    #4f4e4e75 73.38%,
    #ffffff85 104.25%
  );
  height: 60px;
}

@mixin userTile {
  width: 230px;
  height: 67px;
  border-radius: 10px;
  background: #888888b4;
  border: 0.25px solid #00000020;
  box-shadow: 0px 4px 4px -3px #00000040;
}

/******** End: ACTIVECORE TAB SECTION ********/

/******* start: INPUT EMELEMENTS *********/
@mixin inputField {
  height: 30px;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  padding: 0px 10px;
}

/*
<div className="inputBorder">
*/
@mixin searchInput {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #8f8f8f;
  border-radius: 5px;
  width: 100%;
  padding: 0px 5px;
  &:hover {
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
  }

  input {
    @include inputField();
    width: 100%;
    font-size: 12px;
    &:focus {
      outline: none;
      border: none;
    }
  }

  button {
    width: 20px !important;
    padding: 0px !important;
    height: 20px;
    border: 1px solid #8f8f8f;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #8f8f8f;
    -webkit-text-fill-color: #8f8f8f;

    img {
      width: 20px;
      height: 20px;
    }
  }
}

@mixin filterTile {
  height: 45px;
  background: #aaaaaa;
  border-radius: 10px;
  color: #ffffff;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0px;
  padding: 7px;
  display: flex;

  .top {
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.89;
    margin-left: 10px;
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    button {
      background: none;
      border: none;
      font-size: 18px;
      color: #ffffff;
      -webkit-text-fill-color: #ffffff;
      padding: 0px !important;
    }
  }
}

@mixin inputWithCopy {
  display: grid;
  width: 100%;
  grid-template-columns: auto 8%;
  justify-content: stretch;
  gap: 5px;
  @media screen and (max-width: 640px) {
    grid-template-columns: auto 15%;
  }

  button {
    height: 30px;
    border: 1px solid #8f8f8f;
    border-radius: 5px;
    transition: all 0.2s ease-in-out;
    color: rgba(0, 0, 0, 0.357);
    -webkit-text-fill-color: rgba(0, 0, 0, 0.357);
    font-size: 10px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.15);
    }
    &:active {
      box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
    }
  }
}

@mixin rockerCombo {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .enterAmount {
    display: flex;
    justify-content: center;
    gap: 10px;
    .amountInfo {
      /* Chrome, Safari, Edge, Opera */
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        width: 120px;
        height: 60px;
        border: 1px solid #8f8f8f;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f8f8f;
        -webkit-text-fill-color: #8f8f8f;
        font-size: 12px;
        text-align: center;
      }
    }

    .buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 60px;

      button {
        border-radius: 50%;
        height: 26px;
        width: 26px;
        border: 1px solid #8f8f8f;
        font-size: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #8f8f8f;
        -webkit-text-fill-color: #8f8f8f;
        background: none;
      }
    }
  }

  form {
    width: 100%;
  }
  .submitButton {
    padding-top: 10px;
    width: 100%;
    button {
      @include buttonA();
      width: 100%;
    }
  }
}

/******* end: INPUT EMELEMENTS *********/

/******* start: BUTTONS ************/
@mixin buttonA {
  height: 30px;
  background: #702632;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  -webkit-text-fill-color: #ffffff;
  transition: all 0.2s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
  }

  &:active {
    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
}

@mixin buttonC {
  height: 30px;
  background: #eeeeee;
  border: 1px solid #0000002c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  -webkit-text-fill-color: #000000;
  color: #000000;
  &:hover {
    box-shadow: 0px 0px 4px 3px rgba(0, 0, 0, 0.118);
  }

  &:active {
    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.2);
  }
}

@mixin phoneButton {
  width: 300px;
  height: 30px;
  display: flex;
  justify-content: space-around;
  background: white;
  border: 1px solid black;
  border-radius: 10px;
  align-items: center;

  button {
    height: 100%;
    border: none;
    width: 50%;
    padding: 5px;
    border-radius: 8.7px;
    background: none;
    transition: 0.2s ease;
    font-size: 14px;
    font-weight: 400;
    -webkit-text-fill-color: #000000;
  }

  .active {
    background: #c4c4c4;
  }
}

@mixin closeButton {
  width: 25px;
  height: 25px;
  border: 1px solid #8f8f8f;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8f8f8f;
  -webkit-text-fill-color: #8f8f8f;

  img {
    width: 25px;
    height: 25px;
  }
}

/******* end: BUTTONS ************/

@mixin dataEntry {
  height: 35px;
  border: 1px solid #000000;
  box-sizing: border-box;
  border-radius: 7px;
  padding: 5px 10px;
  background: #eeeeee;

  @media screen and (max-width: 640px) {
    height: auto;
    width: 300px;
  }
}

@mixin infoTile($width) {
  background: #ffffff;
  border: 0.5px solid #8f8f8f6e;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  border-radius: 25px;
  padding: 5px 15px 20px 15px;
  transition: all 0.2s ease-in-out;
  width: $width;
  @media screen and (max-width: 640px) {
    height: auto;
    width: 300px;
  }

  .title {
    margin-bottom: 0px;
    h1 {
      font-size: 18px;
      font-weight: 500;
    }
  }
}

/******* start: CONTROL TILES ************/

@mixin controlTile {
  @include infoTile(500px);
  @media screen and (max-width: 640px) {
    width: 300px;
  }
  h2 {
    font-size: 12px;
    font-weight: 400;
  }
  h3 {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
  }
  .investContent {
    display: flex;
    flex-direction: column;
    .tokens {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .toggleButtons {
        margin: 10px 0px;
        height: 20px;
        display: flex;
        justify-content: space-around;
        background: #8f8f8f;
        //  border: 1px solid black;
        border-radius: 16px;
        width: 60px;
        button {
          height: 100%;
          border: none;
          width: 50%;
          padding: 5px;
          border-radius: 16px;
          background: none;
          transition: 0.2s ease;
          -webkit-text-fill-color: #000000;
        }
        // .active {
        //   background: #04681a;
        // }
      }
    }
  }
}
