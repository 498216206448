@use '../../stylesheet/elements';
@use '../../stylesheet/paging';
@use '../../stylesheet/textStyle';

.addDealer{
    @include paging.mainPage();

    .pageTitle{
        .pageHeader{
            @media screen and (max-width: 640px){
                @include textStyle.pageHeaderMobile();
            }
        }
    }

    .pageContent{
        padding-top: 2.5rem;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        @media screen and (max-width: 640px){
            justify-content: center;
            padding-top: 20px;
        }

        .addDealership{
            @include elements.infoTile(300px);

            .inputs{
                display: flex;
                flex-direction: column;
                gap: 10px;
                font-size: 14px;
                margin: 10px 0px;
                div{
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    input{
                        border-radius: 5px;
                        border: 1px solid rgba(0, 0, 0, 0.672);
                        padding: 8px;
                    }

                    .errorInput{
                        border: 2px solid red;
                    }
                }
            }

            .button{
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
                margin-top: 20px;

                button{
                    @include elements.buttonC();
                    width: 100px;
                }
            }
        }//end of addDealership

        .managerSection{
            display: flex;
            flex-direction: column;
            gap: 30px;

            .addManagers{
                @include elements.infoTile(500px);
    
                .inputs{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    font-size: 14px;
                    margin-top: 10px;
                    div{
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                        input{
                            border-radius: 5px;
                            border: 1px solid rgba(0, 0, 0, 0.522);
                            padding: 8px;
                        }

                        .errorInput{
                            border: 2px solid red;
                        }
                    }
                    
                }
    
                .button{
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-end;
                    margin-top: 20px;
    
                    button{
                        @include elements.buttonC();
                        width: 100px;
                    }
                }
            }//end of addManagers

            .managerList{
                display: flex;
                flex-direction: column;
                gap: 25px;

                .managerCard{
                    background: #FFFFFF;
                    padding: 5px 15px;
                    border: 1px solid rgba(0, 0, 0, 0.378);
                    border-radius: 25px;
                    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.405);
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    gap: 5px;
                    height: auto;
                    

                    .managerCardTop{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        
                        .managerName{
                            h1{
                                font-weight: 500;
                                font-size: 18px;
                            }
                        }
    
                        .button{
                            border: none;
                            background: none;
                            img{
                                width: 50px;
                                height: 42px;
                                margin-top: 10px;
                                transform: rotate(0deg);
                                transition: transform 0.5s;
                            }

                            .expandPolygon{
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .managerInfo{
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        padding-bottom: 10px;
                        p{
                            margin: 0px;
                        }
                    }

                    .deleteButton{
                        width: 100%;
                        padding-bottom: 10px;
                        display: flex;
                        justify-content: flex-end;

                        button{
                            @include elements.buttonA();
                            width: 100px;
                        }
                    }

                    
                }
            }// end of managerList

            .submitButton{
                display: flex;
                justify-content: flex-end;
                button{
                    width: 300px;
                    padding: 10px;
                    font-size: 18px;
                    font-weight: 600;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.638);
                    -webkit-text-fill-color: #000000;

                    @media screen and (max-width: 640px){
                        width: 100%;
                    }
                }
            }
        }//end of managerSections


    }//end of pageContent
}